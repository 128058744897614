<template>
  <v-row id="agent-form-page">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-form lazy-validation @submit.prevent="saveAgent">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-subheader>
                <v-text-field v-model="model.name" :error-messages="$helpers.errorMsg('name', $v.model.name, 'Name')"
                              placeholder="Name" outlined ref="name" @input="$v.model.name.$touch()" @blur="$v.model.name.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Upload Logo
                  <span v-if="previewLogo" class="mx-2">( Previous uploaded Logo:
                                    <span class="info--text cursor-pointer" @click="viewLogo">{{ previewLogo.name }}</span>)
                                </span>
                </v-subheader>
                <v-text-field readonly v-model="model.logo" placeholder="Upload Logo" outlined ref="logo"
                              @click="$refs.file.click()" required hide-details="auto">
                  <template v-slot:append>
                    <v-icon class="cursor-pointer" @click="$refs.file.click()">mdi-file-upload</v-icon>
                  </template>
                </v-text-field>
                <input type="file" accept="image/*" style="display: none" ref="file" @change="uploadedFile"/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Phone</v-subheader>
                <v-text-field type="text" v-model="model.phone" :error-messages="$helpers.errorMsg('mobile', $v.model.phone, 'Phone', 'phone number')"
                              placeholder="Phone" outlined ref="phone" @input="$v.model.phone.$touch()" @blur="$v.model.phone.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Email</v-subheader>
                <v-text-field v-model="model.email" :error-messages="$helpers.errorMsg('email', $v.model.email, 'Email')"
                              placeholder="Email" outlined ref="email" @input="$v.model.email.$touch()" @blur="$v.model.email.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">ABN</v-subheader>
                <v-text-field v-model="model.abn" :error-messages="$helpers.errorMsg('name', $v.model.abn, 'ABN')"
                              placeholder="ABN" outlined ref="abn" @input="$v.model.abn.$touch()" @blur="$v.model.abn.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Address</v-subheader>
                <v-textarea row-height="9" auto-grow v-model="model.address" :error-messages="$helpers.errorMsg('name', $v.model.address, 'Address')"
                            placeholder="Address" outlined ref="address" @input="$v.model.address.$touch()" @blur="$v.model.address.$touch()"
                            required hide-details="auto"/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Portal Email Notify</v-subheader>
                <v-text-field v-model="model.portal_email_address" :error-messages="$helpers.errorMsg('email', $v.model.portal_email_address, 'Portal Email')"
                              placeholder="Portal Email" outlined ref="portal_email_address" @input="$v.model.email.$touch()" @blur="$v.model.email.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Pincode</v-subheader>
                <v-text-field v-model="model.pincode" :error-messages="$helpers.errorMsg('name', $v.model.pincode, 'Pincode')"
                              placeholder="Pincode" outlined ref="pincode" @input="$v.model.pincode.$touch()" @blur="$v.model.pincode.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Enable</v-subheader>
                <v-switch v-model="model.is_enabled" inset label="" :true-value="1" :false-value="0"></v-switch>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Default Client</v-subheader>
                <v-select v-model="model.default_client_id" :error-messages="$helpers.errorMsg('name', $v.model.default_client_id, 'Select Default Client')"
                          :items="clientsList" placeholder="Select Default Client" @input="$v.model.default_client_id.$touch()" @blur="$v.model.default_client_id.$touch()"
                          outlined ref="default_client_id" required hide-details="auto"/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Primary Color</v-subheader>
                <v-color-picker hide-inputs v-model="model.primary_color"></v-color-picker>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Text Color</v-subheader>
                <v-color-picker hide-inputs v-model="model.text_color"></v-color-picker>
              </v-col>
              <v-col cols="12">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Blurb</v-subheader>
                <quill-editor ref="blurb" v-model="model.blurb" :options="editorOption" />
              </v-col>
            </v-row>
            <div class="d-block d-lg-flex justify-end mt-8">
              <v-btn type="submit" color="primary" class="mr-4" :loading="loading">
                Submit
              </v-btn>
              <v-btn type="button" outlined color="primary" class="mr-4" :disabled="loading"
                     @click.native="$router.push('/agents')">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="mt-16" v-if="agentId != 'create'">
      <webhook-home/>
    </v-col>
  </v-row>
</template>

<script>
import {email, required, helpers} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from 'vuex';

const number = helpers.regex("serial", /^[0-9]{10}$/);

export default {
  components: {
    WebhookHome: () => import("./WebhookHome.vue"),
  },
  data: (vm) => ({
    agentId: vm.$route.params.agentId,
    model: {
      logo: '',
      name: '',
      phone: '',
      email: '',
      address: '',
      portal_email_address: '',
      abn: '',
      is_enabled: 0,
      pincode: '',
      primary_color: null,
      text_color: null,
      blurb: '',
      default_client_id: null
    },
    file: '',
    previewLogo: null,
    loading: false,
    clientOptions: [],
    editorOption: {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'align': [] }],
        ]
      },
      placeholder: `Enter blurb ...`,
    },
  }),
  validations() {
    return {
      model: {
        name: { required },
        phone: { required },
        email: { email, required },
        address: { required },
        portal_email_address: { email, required },
        abn: { required },
        pincode: { required },
        default_client_id: { required },
      }
    }
  },
  computed: {
    ...mapGetters(['clientsList']),

    clientName() {
        const client = _.find(this.clientsList, item => item.value === this.model.default_client_id);
        return this.model.default_client_id && client ? client.text : null;
    },
  },
  created() {
    this.getClientLists();

    if (this.agentId != 'create') {
      this.getSelectedAgent(this.agentId).then(response => {
        if (response && response.logo) {
          this.previewLogo = {
            name: response.name,
            logo: response.logo
          };
          response.logo = '';
        }
        response.primary_color = response.primary_color || '#0c1839';
        response.text_color = response.text_color || '#000000';
        response.default_client_id = response.default_client_id;
        this.model = response;
      });
    }
  },
  methods: {
    ...mapActions(['getSelectedAgent', 'createAgent', 'updateSelectedAgent', 'getClientLists']),

    viewLogo() {
      const image = [];
      image.push(this.previewLogo.logo);
      this.$viewerApi({
        images: image
      })
    },
    uploadedFile(evt) {
      const files = evt.target.files[0];
      if (files == undefined) this.model.logo = '';
      else {
        this.file = files;
        this.model.logo = files.name;
        this.previewLogo = null;
      }
    },
    saveAgent() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$helpers.focusErrorElement(this.$v.model, this.$refs);
        return;
      }
      const reqData = new FormData(),
        headers = { 'Content-Type': 'multipart/form-data' },
        self = this;
      Object.keys(this.model).forEach(key => {
        if (((this.model[key] || key === 'is_enabled') && key != 'logo') || (key == 'logo' && this.file)) {
          if (key != 'logo') reqData.append(key, this.model[key]);
          else reqData.append('logoFile', this.file);
        }
      });
      this.loading = true;
      if (this.agentId != 'create') {
        const updateReq = {
          id: this.model.id,
          data: reqData
        }
        this.updateSelectedAgent(updateReq, headers).then(resp => {
          self.loading = false;
          self.$router.push("/agents");
        }).catch(err => self.loading = false);
      } else {
        this.createAgent(reqData, headers).then(resp => {
          self.loading = false;
          self.$router.push("/agents");
        }).catch(err => self.loading = false);
      }
    },
  },
};
</script>
